import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "./SearchDatabase.css"; // Import the updated CSS file for styling

function formatNumberWithThousandsSeparator(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function SearchDatabase() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    fetch(`https://us-central1-viddiweb.cloudfunctions.net/app/search?term=${searchTerm}`)
      .then(response => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then(data => {
        setSearchResults(data);
      })
      .catch(error => {
        console.error("Search error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
      event.preventDefault(); // Prevent default to avoid any form submission behavior
    }
  };

  return (
    <div className="main">
      <h1>Search</h1>
      <div className="search">
        <TextField
          className="searchtext"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          label="Leitaðu að heimilisfangi"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          className="buttonstyle"
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Leita"}
        </button>
      </div>
      {searchResults.length > 0 && (
        <div className="search-results">
          <table>
            <thead>
              <tr>
                <th>Heimilisfang</th>
                <th>kaupverd</th>
                <th>thinglystdags</th>
                <th>einflm</th>
                <th>Svefnherbergi</th>
                <th>Sveitarfelag</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(result => (
                <tr key={result.id}>
                  <td>{result.heimilisfang}</td>
                  <td>{formatNumberWithThousandsSeparator(result.kaupverd)}.000 kr</td>
                  <td>{result.thinglystdags}</td>
                  <td>{result.einflm} fm</td>
                  <td>{result.fjherb} Svefnherbergi</td>
                  <td>{result.sveitarfelag}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SearchDatabase;

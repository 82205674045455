export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Resume - Icelandic",
    url: "/DownloadPDF",
    cName: "nav-links",
  },
  {
    title: "Kaupskrá Project",
    url: "/SearchDatabase",
    cName: "nav-links",
  },
];

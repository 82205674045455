import React, { useState } from "react";
import "./About.css";
import { Container } from "@mantine/core";


function About() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container px={"2%"} py={"2%"}>
      <div
        className={`TextBackground ${isHovered ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <h3>
          This is the About page, nothing has been added yet but still in
          development
        </h3>
      </div>
    </Container>
  );
}

export default About;

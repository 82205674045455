import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "./App.css";
import Main from "./components/Navbar/Main/Main";
import "./components/Navbar/Main/Main.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div>
        <Main />
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "120%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1",
          }}
        >
        </video>
      </div>
      <Footer />
    </div>    
  );
}

export default App;

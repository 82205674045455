import React from "react";
import "./DownloadPDF.css";

const DownloadPDF = () => {
  const handleDownload = () => {
    const pdfUrl = process.env.PUBLIC_URL + "/FerilskraVidir.pdf";
    // Trigger the download by creating an anchor element and simulating a click event
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Ferilskra Vidir.pdf"; // Set the desired filename for the downloaded file
    link.click();
  };

  return (
    <div>
      <h1>Resume downloads</h1>
      <button className="downloadIT" onClick={handleDownload}>Download Resume Icelandic</button>
    </div>
  );
};

export default DownloadPDF;

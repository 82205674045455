import React from "react";
import "./Footer.css";
import {
  Button,
  Group,
  Burger,
  AppShell,
  Header,
  MediaQuery,
  createStyles,
  MantineProvider,
  Box,
  Navbar as MantineNavbar,
} from "@mantine/core";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: "dark" }}>
          <br />
          <Box>
            <p>{new Date().getFullYear()} Viddi.is</p>
            <a className="viddifooter" href="mailto:vidirsvanbjorns@gmail.com">Contact Viddi</a>
          </Box>
        </MantineProvider>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useState } from "react";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import {
  Button,
  Group,
  Burger,
  AppShell,
  Header,
  MediaQuery,
  createStyles,
  MantineProvider,
  Box,
  Navbar as MantineNavbar,
} from "@mantine/core";
const useStyles = createStyles((theme) => ({
  navbar: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));
const Navbar = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const title = opened ? "Close navigation" : "Open navigation";

  return (
    <nav className="NavbarItems">
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme: "dark" }}
      >
        <AppShell
          fixed
          navbarOffsetBreakpoint="sm"
          header={
            <Header height={50}>
              <Group direction="row" spacing={5}>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    title={title}
                    color="white"
                    size={40}
                    sx={(theme) => ({
                      "&:hover": {
                        backgroundColor: theme.colors.blue[4],
                      },
                    })}
                  ></Burger>
                </MediaQuery>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Box className="viddilogo">
                    <h1>Viddi.is</h1>
                  </Box>
                </MediaQuery>
              </Group>
              <div className={classes.links}>
                <GroupNavItems />
              </div>
            </Header>
          }
          navbar={
            <MantineNavbar
              className={classes.navbar}
              width={{ base: "100%", sm: 0 }}
              hidden={!opened}
            >
              <GroupNavItems />
            </MantineNavbar>
          }
        ></AppShell>
      </MantineProvider>
    </nav>
  );
};

const GroupNavItems = () => {
  return (
    <Group position="left" px={22} py={0}>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <h1>Viddi.is</h1>
      </MediaQuery>
      {MenuItems.map((item, index) => {
        return (
          <Button compact color="blue" radius="sm">
            <li key={index}>
              <a className={item.cName} href={item.url}>
                {item.title}
              </a>
            </li>
          </Button>
        );
      })}
    </Group>
  );
};
export default Navbar;

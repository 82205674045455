import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Home from "../../../pages/Home";
import About from "../../../pages/About";
import DownloadPDF from "../../../pages/DownloadPDF";

import SearchDatabase from "../../../pages/SearchDatabase";

const Main = () => {
  return (
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/About" component={About} />
      <Route exact path="/DownloadPDF" component={DownloadPDF} />
      <Route exact path="/SearchDatabase" component={SearchDatabase} />
    </Switch>
    </BrowserRouter>
  );
};

export default Main;

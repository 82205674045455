import React from "react";
import "./Home.css";
import { Grid } from "@mantine/core";
import { differenceInYears } from "date-fns";
function Home() {
  const current = new Date();
  const mybirth = new Date("1991-05-31");
  const age = differenceInYears(current, mybirth);
  return (
    <>
    <div className="start-bg">
    <h1 className="landing">
      
      <p></p>
    </h1>

    
  </div>
      <Grid className="BoxBackground" grow gutter="lg" columns={12}>
        <Grid.Col className="BoxOne" span={10}>
          <h1>Name: Víðir Snær Svanbjörnsson, Age: {age}</h1>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={5}>
          <h2 className="underScore">Skills</h2>

          <ol className="listOne">
            <li>
              SQL, Web Development, Python, React, C++, Excel, Data Analytics,
              KPI's and more
            </li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={5}>
          <h2 className="underScore">Education</h2>

          <ol className="listOne">
            <li>2017-2020 BSc in Computer Science at Reykjavik University</li>
            <li>
              2014-2018 BSc in Nutrition Science at the University of Iceland
            </li>

            <li></li>
            <li></li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxTwo" span={5}>
          <h2 className="underScore">Work roles</h2>

          <ol className="listOne">
            <li>Global Quality Specialist</li>
            <li>Quality Assurance Specialist</li>
            <li>Veeva System Administrator</li>
            <li>KPI Development</li>
            <li>Subject Matter Expert</li>
            <li>Income registration</li>
            <li>System Trainer</li>
            <li>Black Belt Coach</li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxTwo" span={5}>
          <h2 className="underScore">Recent Work History</h2>

          <ol className="listOne">
            <li>2020-2024 Alvotech</li>
            <li>
              2018-2019 Summer employee Samskip revenue in export department
            </li>

            <li>
              2007-2017 Various summer jobs and jobs while in school in
              different companies
            </li>
            <li></li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={5}>
          <h2 className="underScore">Working in a team</h2>

          <ol className="listOne">
            <li>
              I have a lot of experience working in a team in school as well as
              in various jobs
            </li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={5}>
          <h2 className="underScore">Working with others</h2>

          <ol className="listOne">
            <li>I'm very helpful and respectful when working with others.</li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={10}>
          <h2 className="underScore">Final Project BSc in Computer Science</h2>

          <ol className="listOne">
            <li>
              In my final project I was in a three people team creating a
              website for a norwegian fishing company. We created the website
              fangstdata.no, connecting it to a database with APIs and
              optimizing the database to make it perform better and faster. My
              role in this project was to create a database and optimize it with
              my knowledge from the courses I've taken.
            </li>
          </ol>
        </Grid.Col>
        <Grid.Col className="BoxOne" span={10}>
          <h2 className="underScore">My BSc in Nutrition Science background</h2>

          <ol className="listOne">
            <li>
              In 2014 I decided I wanted to study Nutrition Science with great
              interest. I wanted to learn more about food and nutrition and how
              it affects the human body.
            </li>
          </ol>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default Home;
